<template>
<!-- 课程详情 课程目录 -->
    <div class="catalogCurriculum" :class="{catalogCurriculumHeight:whole.length > 6}">
      <div class="">
        <div :class="{forConcentBac:listIndex == is||index == is}" v-for="(it,is) in whole" :key="is" @click="listClick(it,is)">
          <div class="forConcent">
            <div class="title">{{it.title}}</div>
            <div class="time" v-if="index == is">
              <span class="timeTitle">积分：{{it.study_point}}</span>
              <div style="color:#47d7e3;">
                <img src="@/assets/img/play/33a5.png" alt="">
                正在播放
              </div>
            </div>
            <div class="time" v-else>
              <span class="timeTitle">积分：{{it.study_point}}</span>
              <img src="@/assets/img/play/a20fa.png" alt="">
            </div>
          </div>
       </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "catalogCurriculum",
    props: {
        whole: {
            type: Array,
            default: [],
        },
        index:{
          type:Number,
          default:-1,
        }
    },
    data() {
        return {
          listIndex:-1,//点击下标
        };
    },
    created(){
      // console.log('赋值',this.index)
      // this.index = this.listIndex
    },
    methods: {
      listClick(vid,e){
        // console.log('sjsj',vid)
        this.listIndex = e;
        this.$emit('on-change', {
          vidPro:vid.url,
          video:vid.id,
          ind:e,
          remain_time:vid.remain_time,
          see_long:vid.see_long,
          looked:vid.looked
        })
      }
    },
};
</script>

<style scoped>
.catalogCurriculum {
    /* width: 93%; */
    /* height: 1381px; */
    /* padding: 0 30px; */
    /* overflow-y: auto; */
    text-align: initial ;
}
.catalogCurriculumHeight{
  height: 1381px;
  overflow-y: auto;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.forConcent{
  width: 90%;
  border-bottom: 1px solid #e9e9e9;
  margin: 0 auto;
}
.forConcentBac{
  background: #f7f7f7;
}
.title{
  padding-top: 17px;
  font-size: 18px;
  font-weight: bold;
  color:#333333;
}
.time{
  color: #9F9F9F;
  font-size: 14px;
  display: flex;
  text-align: initial;
  align-items: center;
  padding: 13px 0 18px;
}
.time .timeTitle{
  margin-right: 10px;
}
.time .timeSpan{
  font-size: 12px;
  color: #47D7E3;
  display: flex;
  align-items: center;
}
.time img{
  width: 13px;
  height: 13px;
  margin-right: 10px;
}
</style>