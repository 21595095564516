<template>
    <!-- 课程详情评论 -->
    <div class="commentStyle" :class="{ commentStyleHeight: wholeComment.length > 6 }" >
        <!-- 最外边的div -->
        <div>
            <!-- for 循环的div -->
            <div class="commentFor" v-for="(it, is) in wholeComment" :key="is">
                <!-- 头像div -->
                <div class="commentForLeft">
                    <img :src="it.portrait" alt="" />
                </div>
                <div class="commentForRig">
                    <p class="commentForRigName">{{ it.name }}</p>
                    <!-- 评论div -->
                    <div class="commentForRigContent">
                        {{ it.content }}
                    </div>
                    <!-- 时间 回复 div -->
                    <div class="commentForRigClick">
                        <p>
                            {{ it.ctime }}
                        </p>
                        <div class="commentForRigClickPro" @click="replyComment(it.cc_id)">
                            <img src="@/assets/img/play/186.png" alt="" />
                            回复
                        </div>
                    </div>
                    <div class="fatherReply" v-if="comment == it.cc_id">
                        <div>
                          <input type="text" v-model="fatherReply" placeholder="在此写下您的看法">
                        </div>
                        <p @click="fatherClick(it.cc_id,it.id)">发表评论</p>
                    </div>
                    <!-- 展示更多文字 div -->
                    <div class="commentForRigMore" v-if="it.next_count != 0&&openComment.indexOf(it.cc_id)===-1" @click="moreClick(it.cc_id,is)">展示剩余{{it.next_count}}条评论</div>
                    <!-- 回复展示内容div -->
                    <div v-if="openComment.indexOf(it.cc_id)>-1" class="sonComment">
                        <div class="sonCommentFor" v-for="(ie,ia) in commentCC" :key="ia">
                            <div style="display:flex;justify-content:space-between;">
                                <div class="sonCommentLeft">
                                    <img :src="ie.portrait" alt="" />
                                </div>
                                <div class="sonCommentRig">
                                  <div class="sonCommentRigHead">
                                      <div class="sonCommentRigHeadName">
                                          <p>{{ie.name}}</p>
                                          <div>{{ie.ctime}}</div>
                                      </div>
                                      <div class="sonCommentRigClick" @click="sonCommentRigClick(ie.cc_id)">
                                          <img src="@/assets/img/play/186.png" alt="" />
                                          <span>回复</span>
                                      </div>
                                      
                                    </div>
                                    <div class="sonCommentRigWord">
                                        回复  <span style="font-weight: bold;font-size:16px;">{{ie.user_pid_name}}</span>  {{ie.content}}
                                    </div>
                                </div>
                            </div>
                            <div class="fatherReply" v-if="sonComment == ie.cc_id">
                                <div>
                                  <input type="text" v-model="sonReply" placeholder="在此写下您的看法">
                                </div>
                                <p @click="sonClick(ie.cc_id,ie.id,it.cc_id)">发表评论</p>
                            </div>
                        </div>
                        <div style="text-align:end;" @click="sonClickpro(it.cc_id)">收起评论</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { play_comment,add_comment } from '@/utils/Api/play'
export default {
    name: "commentStyle",
    props: {
        wholeComment: {
            type: Array,
            default: [],
        },
        id:{
          type:String,
          default:null,
        }
    },
    data() {
        return {
          // more:-1,//展开剩余评论判断显隐
          commentCC:[],//查询到子评论
          comment:-1,//一级评论判断显隐
          sonComment:-1,//二级评论判断显隐
          fatherReply:null,//父回复
          sonReply:null,//子回复
          commentWhether:null,//评论返回信息
          openComment: []
        };
    },
    methods: {
      // 点开剩余评论
      moreClick(e,i){
        console.log('dhkskfhgk',this.$store.getters.token == '')
        if(this.$store.getters.token != ''){
         this.playComment(e,i)
        }else{
          this.$message.error(`请先去登录`)
        }
      },  
      // 查看子评论
      async playComment(e){
        this.commentCC = await play_comment({comment_id:e})
        this.openComment.push(e)
      },
      // 收起评论
      sonClickpro(i){
        let index = this.openComment.indexOf(i)
        this.openComment.splice(index, 1)
      },
      // 父回复信息点击事件
      replyComment(e){
        this.comment == -1 ? this.comment = e : this.comment == e ? this.comment = -1 : this.comment = e
        if(this.comment != -1){
          this.sonComment = -1
        }
      },
      // 子回复信息点击事件
      sonCommentRigClick(e){
        // console.log('点击',e)
        this.sonComment == -1 ? this.sonComment = e : this.sonComment == e ? this.sonComment = -1 : this.sonComment = e
        if(this.sonComment != -1){
          this.comment = -1
        }
      },
      // 父类回复发表评论点击
      fatherClick(e,s){
        this.addComment(e,this.fatherReply,s,e)
      },
      // 子类回复发表评论点击
      sonClick(e,s,a){
        this.addComment(e,this.sonReply,s,a)
      },
      // 评论 e 是cc_id i 是双向绑定输入框内容 s 是回复或评论用户id a 是父级的评论id
      async addComment(e,i,s,a){
        if(i != null){
          let information = await add_comment({course_id:this.id,content:i,user_pid_id:s,comment_pid_id:e,main_comment_id:a})
          if(information.length == 0){
            this.fatherReply = null
            this.sonReply = null
            i == this.fatherReply ? this.$emit('comment-change', 1) : this.playComment(a)
            this.$emit('comment-change', 1) 
            this.$message.success(`评论成功`) 
            
          }
       }else{
          this.$message.error(`请先填写内容`)
        }
      }
      
    },
};
</script>

<style>
.commentStyle {
    padding: 0 27px;
    text-align: initial;
}
.commentStyleHeight {
    height: 1381px;
    overflow-y: auto;
}

/* 评论展示 */
.commentFor{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;
  padding: 30px 0;
}
.commentForLeft{
  flex:1;
}
.commentForLeft img {
    width: 39px;
    height: 39px;
    border-radius: 50px;
    overflow: hidden;
}
.commentForRig{
  flex:14;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commentForRigName{
  height: 39px;
  display: flex;
  align-items: center;
}
.commentForRigContent {
    font-size: 14px;
    color: #333333;
    margin: 9px 0 18px;
}
.commentForRigClick{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commentForRigClick p{
  color: #afaeae;
  font-size: 14px;
}
.commentForRigClickPro{
  font-size: 14px;
  color: #545e68;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.commentForRigClickPro>img{
  width: 18px;
  height: 16px;
  margin-right: 8px;
}
.commentForRigMore{
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
}
.sonComment{
  margin-top: 16px;
  border-radius: 10px;
  padding: 10px 36px;
  background: #F8F9FA;
}
.sonCommentFor{
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 40px;
}
.sonCommentLeft{
  flex:1;
}
.sonCommentLeft img{
  width: 39px;
  height: 39px;
  border-radius: 50px;
  overflow: hidden;
}
.sonCommentRig{
  flex:11;
  display: flex;
  flex-direction:column;
}
.sonCommentRigHead{
  margin-bottom: 8px;
  display: flex;
  justify-content:space-between;
  align-items:center;
}
.sonCommentRigHeadName>p{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 5px;

}
.sonCommentRigHeadName>div{
  font-size: 14px;
  color: #afaeae;
}
.sonCommentRigClick{
   font-size: 14px;
  color: #545e68;
  display: flex;
  align-items: center;

}
.sonCommentRigClick>img{
  width: 18px;
  height: 16px;
  margin-right: 8px;
  margin-top: 3px;
}
.fatherReply{
  display: flex;
  justify-content:center;
  margin: 22px 0 0;
}
.fatherReply>div{
  width: 80%;
  height: 30px;
  border:1px solid #bdb7b7;
}
.fatherReply>div>input{
  width: 97%;
  height: 100%;
  font-size: 14px;
  padding-left: 10px;
}
.fatherReply>p{
  width: 75px;
  height: 32px;
  background: #3ac6cf;
  display: flex;
  justify-content:center;
  align-items:center;
  color:#fff;
}
</style>